<template>
  <div ref="chart" :style="{height:height,width:width}"></div>
</template>
<script>
import {debounce} from 'lodash'
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
        type:Object,
        default:{
            xAxisData: [],
            seriesData:[]
        }
    },
    legendData:{
        type:Array,
        default:[]
    },
    units:{
        type:Array,
        default:[]
    },
    yAxis:{
        type:Array,
        default:[]
    },
    yAxisIndex:{
      type:Array,
      default:0
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    type:{
      type: String,
      default: '1'
    },
  },
  data() {
    return {
      color:['rgba(35,142,255,0.7)','rgba(62,235,255,0.7)','rgba(255,234,0,0.7)','rgba(52,250,89,0.7)','rgba(116,70,256,0.7)','rgba(74,121,216,0.7)'],
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    }
  },

  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      let arr = _this.chartData.seriesData || [];
      let seriesData = [];
      arr.forEach((item,i) => {
          seriesData.push({
            name: item.name,
            yAxisIndex:_this.yAxisIndex[i],
            type: "line",
            showSymbol: false,
            symbol: 'circle',
            smooth: true,
            lineStyle: {
              color: item.name.indexOf("交易笔数") != -1?_this.color[0]:item.name.indexOf("交易金额") != -1?_this.color[1]:item.name.indexOf("未兑付金额") != -1?_this.color[2]:item.name.indexOf("兑付比") != -1?_this.color[3]:_this.color[i],
              width:1
            },
            itemStyle: {
              color: item.name.indexOf("交易笔数") != -1?_this.color[0]:item.name.indexOf("交易金额") != -1?_this.color[1]:item.name.indexOf("未兑付金额") != -1?_this.color[2]:item.name.indexOf("兑付比") != -1?_this.color[3]:_this.color[i],
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: item.name.indexOf("交易笔数") != -1?_this.color[0]:item.name.indexOf("交易金额") != -1?_this.color[1]:item.name.indexOf("未兑付金额") != -1?_this.color[2]:item.name.indexOf("兑付比") != -1?_this.color[3]:_this.color[i], // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#0000", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            label:{
              formatter:'{a}'+item.unit
            },
            data: item.data,
          })
      });
      let options =  {
        tooltip: {
          transitionDuration:0,
          trigger: "axis",
          backgroundColor:"#09214d",
          borderWidth:1,
          borderColor:"#7ccaff",
          axisPointer:{
            type:"cross",
            lineStyle:{
              type: 'dashed',
              // color:"rgba(102,163,215,0.3)"
            }
          },
          formatter:function(params){
            //console.log(params)
            let html = ""
            html+= params[0].name + '<br>'
            params.forEach((res,i)=>{
              //html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>'+_this.units[i]+'<br>'
              if(res.seriesName.indexOf("笔") != -1){
                html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>笔<br>'
              }else if(res.seriesName.indexOf("金额") != -1){
                html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>元<br>'
              }else if(res.seriesName.indexOf("比") != -1){
                html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>%<br>'
              }else{
                html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>'+_this.units[i]+'<br>'
              }
            })
            return html;
          }
        },
        title: {
          // text: '需求资源数',
          textStyle: {
            color: "#507998",
            fontWeight: "normal",
            fontSize: 16,
          },
        },
        grid: {
          left: "40",
          right: "40",
          bottom: "40",
          containLabel: true,
        },
        legend: {
          top:10,
          right: "4%",
          itemGap: 40,
          icon: "circle",
          data: _this.legendData,
          textStyle: {
              fontSize: 14,
              color: "#5bd5f7"
          }
        },
        axisPointer: {
          link: {
            xAxisIndex: 'all'
          },
          label: {
            backgroundColor: 'transparent'
          }
        },
        xAxis: {
          data: _this.chartData.xAxisData,
          splitNumber:7,
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: "rgba(102,163,255,0.4)",
            },
          },
          axisLabel: {
            interval: (this.type == 3 || this.type == 4) ? 0 : this.type == 2 ? 4 : 'auto',
            textStyle: {
              fontSize: 14,
              color: "rgba(91,213,247,0.6)",
            },
            showMaxLabel: (this.type == 3 || this.type == 4) ? false : true,
            formatter: (value, index)=>{
              if(this.type == 3 || this.type == 4){
                // console.log(index)
                if(value.lastIndexOf('-01') === 7){
                  // console.log("1111",value)
                  return value.slice(0,7)
                }else{
                  return ''
                }

              }else{
                return value
              }
            }
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [],
        // '资源申请数','已提供资源数','需求项超时数'
        series: seriesData
      }
      this.yAxis.map((item, i) => {
        if(item=='%'){
          options.yAxis.push({
            //name:item,
            min:0,
            max:100,
            scale: true,
            splitLine: {
              lineStyle: {
                color: "rgba(102,163,255,0.2)",
                type: "solid",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(102,163,255,0.4)",
              },
            },
            axisLabel: {
              formatter: '{value}'+item,
              textStyle: {
                fontSize: 14,
                color: "rgba(91,213,247,0.6)",
              },
            },
            axisTick: {
              show: false,
            },
          });
        }else{
          options.yAxis.push({
            //name:item,
            min:null,
            max:null,
            scale: true,
            splitLine: {
              lineStyle: {
                color: "rgba(102,163,255,0.2)",
                type: "solid",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(102,163,255,0.4)",
              },
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                fontSize: 14,
                color: "rgba(91,213,247,0.6)",
              },
            },
            axisTick: {
              show: false,
            },
          });
        }
      });
      // 绘制图表
      this.chart.setOption(options);
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
